import React from "react"

function Form() {
    return (
        <section className="section section-form">
            <div className="container">
                <div className="form-box">
                    <div className="social-box">
                        <a href="https://twitter.com/annaabramowski" target="_blank" rel="noopener noreferrer"><img src={`../../assets/images/twitter.png`} alt="Find me on Twitter" width="30" height="30" /></a>
                        <a href="https://www.linkedin.com/in/abramowski/" target="_blank" rel="noopener noreferrer"><img src={`../../assets/images/linkedin.png`} alt="Contact me on Linkedin" width="30" height="30" /></a>
                        <a href="https://www.facebook.com/DrAnnaAbramowski/" target="_blank" rel="noopener noreferrer"><img src={`../../assets/images/facebook.png`} alt="Find me on Facebook" width="30" height="30" /></a>
                        <a href="https://www.instagram.com/dr_anna_abramowski" target="_blank" rel="noopener noreferrer"><img src={`../../assets/images/instagram.png`} alt="Find me on Instagram" width="30" height="30" /></a>
                    </div>

                    <div className="col6">
                        <div className="form-txt">
                            <h2>Get in touch with us by filling in this secure form</h2>
                            <p className="lead">All enquiries will be treated in accordance with the Data Protection Act 1998 (DPA) and the British Psychological Society (BPS) code of ethics:</p>
                        </div>

                        <div className="certification-box">
                            <img src={`../../assets/images/hcpc-reg_orig.png`} alt="Health and Care Professions Council" width="151" height="129" />
                            <img src={`../../assets/images/bps.png`} alt="British Psychological Society" width="254" height="128" />
                        </div>
                    </div>

                    <div className="col6">
                        <form name="contact_form" action="https://getform.io/f/0f6b0ead-2674-4092-8eeb-13bbf48ca36a" method="POST">
                            <div className="field">
                                <label htmlFor="name">Your name: </label>
                                <input type="text" name="name" id="name" required />
                            </div>

                            <div className="field">
                                <label htmlFor="email">Your email address: </label>
                                <input type="email" name="email" id="email" required />
                            </div>

                            <div className="field">
                                <label htmlFor="phone">Your phone number: </label>
                                <input type="tel" name="phone" id="phone" />
                            </div>

                            <div className="field">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" cols="30" rows="8" />
                            </div>

                            <div className="field">
                                <small className="security-info">We hold all your information securely in accordance with BPS guidelines and the Data Protection Act.</small>
                            </div>

                            <div className="clear"></div>

                            <div className="field field-submit">
                                <div className="g-recaptcha" data-sitekey="6Lci2fcUAAAAAPxagu9afly-D6xo56YZUrjpBUR_"></div>
                                <button type="submit" className="button">Submit form</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Form

